<template>
  <div class="page">
    <div class="s1 p-card fs-16 m-3">
      <div class="flex h-between p-3 pointer"
           @click="clearTime">
        <div>关键词（{{ keywords.length }}）</div>
        <div>
          <div class="p-icon-arrow" />
        </div>
      </div>

      <div class="divider ml-3" />
      <div class="tags p-3">
        <div class="tag"
             v-for="(item, index) in keywords"
             :key="item">
          <div class="name">{{ item }}</div>
          <div class="icon-del"
               @click="keywords.splice(index, 1)" />
        </div>
        <div class="tag add">
          <div v-if="!isAdd"
               class="flex mask">
            <div class="icon-add" />
            <div>添加关键词</div>
          </div>
          <input type="text"
                 autofocus
                 class="inp"
                 v-model="tagValue"
                 placeholder="请输入"
                 @focus="isAdd = true"
                 @blur="addTag" />
        </div>
      </div>
    </div>
    <div class="s1 p-card fs-16 m-3">
      <div class="flex h-between p-3 pointer"
           @click="handleChose()">
        <div>匹配方式</div>
        <div class="flex">
          <div class="fs-16 b mr-1">{{ dialog1_pick.name }}</div>
          <div class="p-icon-arrow" />
        </div>
      </div>
    </div>
    <div class="s1 p-card fs-16 m-3">
      <div class="flex h-between p-3 pointer"
           @click="goGroup">
        <div>生效群</div>
        <div class="flex">
          <div class="fs-16 b mr-1">{{[...choseGroup].length}}个</div>
          <div class="p-icon-arrow" />
        </div>
      </div>
      <div class="color-gray fs-12 ml-3 pb-3">
        生效的群，可以开启群内的关键词回复（仅托管群可见）
      </div>
    </div>
    <div class="s2 p-card fs-16 m-3">
      <div class="flex h-between p-3 pointer"
           @click="goEdit(1)">
        <div>回复内容（{{ contentMsgs.length }}）</div>
        <div>
          <div class="p-icon-arrow" />
        </div>
      </div>
      <div class="divider-dashed ml-3" />
      <div class="b2">
        <no-data v-if="contentMsgs.length === 0"
                 title="暂未添加回复内容"
                 btn-text="新增回复内容"
                 @click="goEdit(0)" />
        <div v-for="(item, index) in contentMsgs"
             :key="index"
             class="item">
          <msg-box :data="item" />
        </div>
      </div>
      <van-action-sheet v-bind:show="dialog1"
                        :actions="actions1"
                        @select="dialog1Hide"
                        cancel-text="取消"
                        close-on-click-action
                        @cancel="dialog1 = false" />
    </div>
    <div class="p-footer h-end">
      <div class="flex  flex-right">
        <button class="btn auto"
                @click="submit(0)">取消</button>
        <button class="btn auto fill ml-3"
                @click="submit(1)">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import msgBox from '@/components/msg-box/index.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'KeyWords',
  components: { msgBox },
  data () {
    return {
      show: true,
      actions: [
        { name: '着色选项', color: '#ee0a24' },
        { name: '禁用选项', disabled: true },
        { name: '加载选项', loading: true }
      ],
      status: false,
      chatroomList: [],
      contentMsgs: [],
      keywords: [],
      keywordId: '',
      isAdd: false,
      tagValue: '',
      dialog1: false,
      dialog1_pick: {
        name: '精准匹配',
        value: 1
      },
      actions1: [
        {
          name: '模糊匹配',
          value: 0
        },
        {
          name: '精准匹配',
          value: 1
        }
      ],
      inter: '',
      choseGroup: new Map()
    }
  },
  computed: {
    ...mapGetters(['contentData', 'globalData', 'robotInfo'])
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const _this = this
      // console.log(_this.contentData)
      if (_this.$route.query && _this.$route.query.id && (_this.contentData && !_this.contentData.keywords)) {
        _this.keywordId = _this.$route.query.id
        _this.getData()
      } else {
        _this.getGlobal()
      }
    },
    getGlobal () {
      const _this = this
      if (_this.contentData.keywords) {
        _this.keywords = _this.contentData.keywords
      }
      if (_this.contentData.choseGroup) {
        _this.choseGroup = _this.contentData.choseGroup
      }
      if (_this.contentData.pick) {
        _this.dialog1_pick = _this.contentData.pick
      }
      if (_this.contentData.keywordId) {
        _this.keywordId = _this.contentData.keywordId
      }
      if (_this.globalData.contentList) {
        _this.contentMsgs = _this.globalData.contentList
      }
    },
    getData () {
      const _this = this
      _this.$get('/KeywordReply/GetKeywordReplyDetail', { keywordId: _this.keywordId }).then((res) => {
        _this.keywords = res.keywords
        res.contentList.map(item => { item.isAtNewcomer = false })
        _this.contentMsgs = res.contentList
        _this.choseGroup.clear()
        res.chatroomList.map(item => {
          _this.choseGroup.set(item.chatroomId, true)
        })
        _this.dialog1_pick = _this.actions1[res.matchingMode]
        _this.setContent()
      })
    },
    statusChange () {
      this.$post('/JoinChatroomGreet/JoinChatroomGreetStatusSetting', {
        status: this.status ? 1 : 0
      }).then((res) => {
        this.$Toast('切换成功')
      })
    },
    goGroup () {
      const _this = this
      _this.setContent()
      _this.$router.push('/choseGroup/index')
    },
    goEdit (n) {
      const _this = this
      _this.setContent()
      // _this.$router.push({
      //   path: '/contentEdit',
      //   query: {
      //     isEdit: n
      //   }
      // })
      _this.$router.replace({
        path: '/contentEdit',
        query: {
          isEdit: n
        }
      })
    },
    setContent () {
      const _this = this
      _this.$store.commit('setGlobalData', {
        contentList: _this.contentMsgs
      })
      _this.$store.commit('setContentData', {
        keywords: _this.keywords
      })
      _this.$store.commit('setContentData', {
        choseGroup: _this.choseGroup
      })
      _this.$store.commit('setContentData', {
        pick: _this.dialog1_pick
      })
      _this.$store.commit('setContentData', {
        keywordId: _this.keywordId
      })
      _this.$store.commit('setContentData', {
        isKeyword: true
      })
    },
    addTag () {
      this.isAdd = false
      if (this.tagValue) {
        this.keywords.push(this.tagValue)
      }
      this.tagValue = ''
    },
    handleChose () {
      const _this = this
      _this.dialog1 = true
    },
    dialog1Hide (e) {
      this.dialog1_pick = e
      this.dialog1 = false
    },
    async submit (n) {
      const _this = this
      const chatroomList = []
      for (const key of _this.choseGroup.keys()) {
        chatroomList.push({ robotWxId: _this.robotInfo.robotWxId, chatroomId: key })
      }
      if (n === 1) {
        await _this.$post('/KeywordReply/SaveKeywordReply', {
          keywordId: _this.keywordId ? parseInt(_this.keywordId) : 0,
          keywords: _this.keywords,
          matchingMode: _this.dialog1_pick.value,
          chatroomList: chatroomList,
          contentList: _this.contentMsgs
        }).then((res) => {
          _this.$Toast('保存成功')
        })
      }
      _this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
<style>
.van-action-sheet__item {
  box-sizing: border-box;
}
</style>
